import React from 'react';
import Layout from '../components/Layout'
import styled from "styled-components"
import { Link } from 'gatsby';
import Seo from '../components/Seo'

const Wrapper404 = styled.section`
    position: absolute;
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;

const H404 = styled.h1`
    text-align: center;
    padding: 15px;
    font-size: 64px;
    letter-spacing: 0.2em;
    margin: 0;
`;

const PNF = styled.p`
    margin-left: -16px;
`;

const GoBack = styled(Link)`
    margin-left: -16px;
    text-decoration: underline;
    transition: .2s;
    &:hover {
        color: #8b8585;
        transition: .2s;
    }
`;

export default() => {
    const title = 'Page not found';
    return (
        <Layout>
            <Seo 
                title = {title}
            />
            <Wrapper404>
                <H404>404</H404>
                <PNF>{title}</PNF>
                <GoBack to='/' >Back to site</GoBack>
                
            </Wrapper404>
        </Layout>
    )
}